var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-section',{staticClass:"grey lighten-4",attrs:{"id":"financial-report"}},[_c('base-section-heading',{attrs:{"color":"grey lighten-2","title":"Laporan Keuangan"}}),_c('v-container',[_c('v-tabs',{attrs:{"background-color":"#114e87","dark":""}},[_c('v-tab',{attrs:{"href":"#tahunan"}},[_vm._v("Tahunan")]),_c('v-tab-item',{attrs:{"value":"tahunan"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mx-2",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.header,"items":_vm.items.financialreport,"search":_vm.search,"item-key":"claim_refno","footer-props":{
              showFirstLastPage: true,
              firstIcon: 'mdi-page-first',
              lastIcon: 'mdi-page-last',
              prevIcon: 'mdi-chevron-left',
              nextIcon: 'mdi-chevron-right',
            },"dense":"","loading":_vm.loading.list,"loading-text":"Loading... Please wait"},on:{"input":function($event){return _vm.Check($event)}},scopedSlots:_vm._u([{key:"item.action",fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#00b0e4","title":"Attachment"},on:{"click":function($event){return _vm.GetAttachment(item.claim_row)}}},[_vm._v("mdi-download-box-outline")]),_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Remarks","color":"#001f48"},on:{"click":function($event){return _vm.SetRemarks(
                    'updateremark',
                    item.claim_row,
                    item.claim_remarks
                  )}}},[_vm._v("mdi-note-text-outline")]),_c('v-icon',{attrs:{"title":"Timeline","color":"#FF6600"},on:{"click":function($event){return _vm.CheckTimeline(item)}}},[_vm._v("mdi-timeline-question-outline")])]}}])})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }