<template>
  <base-section id="financial-report" class="grey lighten-4">
    <base-section-heading color="grey lighten-2" title="Laporan Keuangan" />

    <v-container>
      <v-tabs background-color="#114e87" dark>
        <v-tab href="#tahunan">Tahunan</v-tab>
        <!-- <v-tab href="#triwulan">Triwulan</v-tab> -->
        <!-- <v-tab href="#bulanan">Bulanan</v-tab> -->
        <!-- <v-tab href="#keberlanjutan">Keberlanjutan</v-tab> -->
        <v-tab-item value="tahunan">
          <v-card flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mx-2"
            ></v-text-field>
            <v-data-table
              :headers="header"
              :items="items.financialreport"
              :search="search"
              @input="Check($event)"
              item-key="claim_refno"
              class="elevation-1"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
              }"
              dense
              :loading="loading.list"
              loading-text="Loading... Please wait"
            >
              <template v-slot:item.action="{ item }">
                <v-icon
                  class="mr-2"
                  color="#00b0e4"
                  @click="GetAttachment(item.claim_row)"
                  title="Attachment"
                  >mdi-download-box-outline</v-icon
                >
                <v-icon
                  class="mr-2"
                  title="Remarks"
                  color="#001f48"
                  @click="
                    SetRemarks(
                      'updateremark',
                      item.claim_row,
                      item.claim_remarks
                    )
                  "
                  >mdi-note-text-outline</v-icon
                >
                <v-icon
                  title="Timeline"
                  color="#FF6600"
                  @click="CheckTimeline(item)"
                  >mdi-timeline-question-outline</v-icon
                >
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionFinancialReport",
  data: () => ({
    items: {
      financialreport: [],
    },
    search: "",
    loading: {
      list: false,
    },
    header: [
      { text: "No ", value: "" },
      { text: "Nama", value: "" },
      { text: "Tahun", value: "" },
      { text: "Uploaded", value: "" },
      { text: "Download", value: "" },
    ],
  }),
};
</script>
